import banner from "../../misc/singing_valentines_social_1.jpg";

export default function SingingValentines () {
    return (
        <>
        <h1 class="h1 text-center w-11/12 m-auto pt-8 pb-12" id="subPageHeaderTitle">Singing Valentines!</h1>
            <div className='w-11/12 m-auto mt-0'>
                <div className='text-justify pb-10'>
                    <div className='bg-slate-800 rounded-2xl p-5'>
                        <p>Our most celebrated fundraiser is back and more popular than ever!</p>
                        <br></br>
                        <img className='m-auto h-1/3 w-1/3' src={banner} alt='Event Banner'></img>
                        <br></br>
                        <p>Send someone the gift of harmony this valentines day! Our singers are prepared to visit the home, work, school, or community of someone important to you and perform a few love songs.
                        </p>
                        <p>We have a few options for 2025:
                            <ul className='list-disc'>
                                <li>Friday, February 14 -- Valentines' Day itself</li>
                                <li>Saturday, February 15 -- the ever-popular "I didn't really forget" option!</li>
                                <li>or yet another day by special request, subject to availability.</li>
                            </ul>
                        </p>
                        <br></br>
                        <p>Here's what you get with your order:
                            <ul className='list-disc'>
                                <li>A quartet of singers from the Greater Boston Chapter (VoMo or VR)</li>
                                <li>Two love songs</li>
                                <li>A small bag of chocolate kisses [optional]</li>
                                <li>A silk rose [optional] or a dozen fresh roses [$39 extra]</li>
                            </ul>
                        </p>
                        <br></br>
                        <p>There are a variety of timeslots and price ranges available, including:
                            <ul className='list-disc'>
                                <li>$75 for a four-hour delivery window</li>
                                <li>$115 for a two-hour delivery window</li>
                                <li>$150 for a one-hour delivery window (subject to availability)</li>
                                <li>30% discount on multiple deliveries within walking distance</li>
                            </ul>
                        </p>
                        <br></br>
                        <div className ='flex justify-center pt-3'>
                            <a className='w-[33%]' href="https://docs.google.com/forms/d/e/1FAIpQLSfWo1AbFMszhtEMxxmHtU3Uk1ehjdospMImxdPr1miHFGFlFA/viewform"><button style={{width:'100%'}} className ="homePageButton w-full text-xl" id="auditionInfoButton">
                            Order Now!
                            </button>
                            </a>
                        </div>
                        <br></br>
                        <p>Use the form to order, submit questions, and share additional information you think we need to know. Please include all desired locations and delivery date/time slots-- the more flexible, the better! You can also email us at <span className='font-bold'>singingvalentines@vocalrevolution.org</span> with any details that do not fit on the form.</p>
                        <p>A member of our team will get back to you with a confirmation. Until you get that confirmation, we're still working on making it happen, so stay in touch with us!</p>
                        <p className='italic'>***Pricing and timing assumes a delivery within the 128 loop around Boston. We do deliver outside this region, but often we need more leeway on getting our singers there! And we can't be accountable for unpredictable weather delays! All quartet members will affirm that they are good health that morning. They can wear masks upon request.***</p>
                    </div> 
                </div>
            </div>
        </>
    )
}