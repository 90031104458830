import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import CommonLayout from "./pages/CommonLayout"
import Footer from "./components/Footer"
import Home from "./pages/Home"
import About from "./pages/About"
import Audition from "./pages/Audition"
import Donate from "./pages/Donate"
import Credits from "./pages/Credits"
import EventPage1 from "./pages/EventPage1"
import EventPage2 from "./pages/EventPage2"
import EventPage3 from "./pages/EventPage3"

function MyRouter() {
  return (
    <Router>
      <CommonLayout/>
        <Routes>
          <Route path='/' element={<Home/>} />
          <Route path='/about' element={<About/>} />
          <Route path='/auditions' element={<Audition/>} />
          <Route path='/donations' element={<Donate/>} />
          <Route path='/holiday-show' element={<EventPage1/>} />
          <Route path='/singing-valentines' element={<EventPage2/>} />
          <Route path='/open-rehearsal' element={<EventPage3/>} />
          <Route path='/credits' element={<Credits/>} />
        </Routes>
      <Footer/>
    </Router>
  )
}

export default MyRouter;