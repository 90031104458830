import 'bootstrap/dist/css/bootstrap.min.css';

export default function EventCalendarCards() {
  const events = [
    { date: "Feb 14-15", title: "Singing Valentines", link: "/singing-valentines",  opacity: 1 },
    { date: "June", title: "Chapter Show", link: "", opacity: 0.5 },
    { date: "July 1-6", title: "BHS International", link: "", opacity: 0.25 }
  ];

  return (
    <>
      <h1 class="h1 text-center" id="pageHeaderTitle">Upcoming Events</h1>
      <div class='calendarCardContainer'>
        {events.map((event, index) => (
          <a key={index} class={`calendar linked`} href={event.link} style={{ opacity: event.opacity }}>
            <div class="calendar-body">
              <span class="month-name">{event.date}</span>
              <span class="eventTitle">{event.title}</span>
            </div>
          </a>
        ))}
      </div>
    </>
  )
}